import React from "react";
import Modal from "../components/Modal"
import "./Subscribe.css";

const Subscribe = () => {
	return (
		<div className="Subscribe-section">
			<div className="Subscribe-title Subscribe-object">
				<h2>¿Quieres saber más?</h2>
			</div>
			<div className="Subscribe-text-section Subscribe-object">
				<p>
					Si te interesa saber como conseguir tu herramienta de trabajo
					financiada, no dudes en contactarnos.
				</p>
			</div>
			<div className="Subscribe-button Subscribe-object">
				<Modal buttonText={"Comenzar"} form={"quotation"} />
			</div>
		</div>
	);
};

export default Subscribe;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home"
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";
import "./App.css";

function App() {
	return (
		<div className="App">
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />}></Route>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;

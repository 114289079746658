import React from "react";

import HomeHeader from "../components/HomeHeader";
import Features from "../components/Features"
import Subscribe from "../components/Subscribe"
import Facts from "../components/Facts"
import Plans from "../components/Plans"
import "./Home.css"

const Home = () => {
	return (
		<div className="home-container">
			<div className="home-color-1 home-color"></div>
			<div className="home-color-2 home-color"></div>
			<div className="home-color-3 home-color"></div>
			<div className="home-color-4 home-color"></div>
			<div className="home-header" id="header">
				<HomeHeader />
			</div>
			<Features/>
			<Subscribe/>
			<Facts/>
			<Plans/>
		</div>
	);
};

export default Home;

import React from "react";
import { motion } from "framer-motion";
import Modal from "./Modal"
import "./PlanCard.css";

const PlanCard = props => {
	return (
		<motion.div
			initial={{ x: "-100" }}
			whileInView={{ x: 0 }}
			transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
			viewport={{ once: true }}
			className="planCard-container"
		>
			<h3>{props.planDetails.name}</h3>
			<img
				src={require(`../assets/images/${props.planDetails.picture}`)}
				alt=""
			/>
			<h2>{props.planDetails.price}</h2>
			<Modal
				buttonText={"Lo quiero"}
				form={"quotation"}
				planName={props.planDetails.name}
			/>
		</motion.div>
	);
};

export default PlanCard;

export const formValidation = state => {
	let errors = [];
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	for (let input in state) {
		if (input === "email" && !state[input].toLowerCase().match(emailRegex)) {
			errors.push(input);
		} else {
			if (state[input].length < 3) errors.push(input);
		}
	}
	return errors.length === 0;
};

export const fieldValidation = event => {
	const onlyLettersRegex = /^[a-zA-Z\s]*$/;
	const onlyNumbersRegex = /^[0-9]*$/;
	const inputName = event.target.name;
	const inputValue = event.target.value;
	let validation = false;

	switch (inputName) {
		case "name":
			if (onlyLettersRegex.test(inputValue)) validation = true;
			break;
		case "phone":
			if (onlyNumbersRegex.test(inputValue)) validation = true;
			break;
		default:
			validation = true;
	}
	return validation;
};

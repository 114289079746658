import React from "react";
import { motion } from "framer-motion";
import "./Features.css";

const Features = () => {
	return (
		<div className="features-container" id="features">
			<section>
				<img src={require("../assets/images/logo/logoFlit.png")} alt="" />
				<h5>Hacemos realidad la movilidad eléctrica</h5>
			</section>
			<motion.section
				initial={{ x: "-100" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<h4>
					Vehiculos <span>eléctricos</span>
				</h4>
				<p>
					Ofrecemos vehículos eléctricos diseñados exclusivamente para
					transformar la industria de la última milla no solo ayudando al medio
					ambiente si no siendo versátiles para moverse por la ciudad y llevar
					una mayor cantidad de pedidos.
				</p>
			</motion.section>
			<motion.section
				initial={{ x: "-100" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<img src={require("../assets/images/fleet.png")} alt="" />
			</motion.section>
			<motion.section
				initial={{ x: "200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<img src={require("../assets/images/handshake.png")} alt="" />
			</motion.section>
			<motion.section
				initial={{ x: "200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<h4>Financiamiento</h4>
				<p>
					Ofrecemos el financiamiento de tu herramienta de trabajo, de una
					manera rápida y sin complicaciones.
				</p>
			</motion.section>
			{/* <motion.section
				initial={{ x: "-100" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<h4>
					Transporte de <span>Última milla</span>
				</h4>
				<p>
					Ofrecemos un servicio de última milla amigable con el medio ambiente,
					con conductores certificados y responsables.
				</p>
			</motion.section>
			<motion.section
				initial={{ x: "-100" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
			>
				<img src={require("../assets/images/boxes.png")} alt="" />
			</motion.section> */}
		</div>
	);
};

export default Features;

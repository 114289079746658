
import React from "react";
import { motion } from "framer-motion";
import "./Facts.css";

import GroupsIcon from '@mui/icons-material/Groups';
import ForestIcon from '@mui/icons-material/Forest';

const Facts = () => {
	return (
		<div className="Facts-section" id="facts">
                <h2 className="Facts-title">Beneficios que ofrece FLiT</h2>
                <div className="Facts-wrapper">
                    <motion.div 
                        initial={{ x: '100' }}
                        whileInView={{ x: 0 }}
                        transition={{type: 'spring', duration:1.5, bounce: 0.3}}
                        viewport={{ once: true }}
                        className="Fact-object">
                        <div className="Fact-icon-section">
                            <GroupsIcon sx={{ fontSize: 100 }} className="color-icon"></GroupsIcon>
                        </div>
                        <div className="Fact-text-section">
                            <h4>Te ayudamos a conseguir mejores oportunidades</h4>
                            <p>En FLiT ofrecemos oportunidades de trabajo con mejores y más beneficios, pensando en ti.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        initial={{ x: '-100' }}
                        whileInView={{ x: 0 }}
                        transition={{type: 'spring', duration:1.5, bounce: 0.3}}
                        viewport={{ once: true }}
                        className="Fact-object">
                        <div className="Fact-icon-section">
                            <ForestIcon sx={{ fontSize: 100 }} className="color-icon"></ForestIcon>
                        </div>
                        <div className="Fact-text-section">
                            <h4>Nos preocupamos por el medio ambiente</h4>
                            <p>En FLiT apoyamos la transición a un transporte amigable con el medioambiente, impulsando el uso la adopción de vehículos electrícos.</p>
                        </div>
                    </motion.div>
                </div>
		</div>
	);
};

export default Facts;

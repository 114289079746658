import React from "react";
import { motion } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";
import "./HomeHeader.css";

const HomeHeader = () => {
	return (
		<div className="HomeHeader-container">
			<div className="HomeHeader-main-container">
				<motion.div 
					initial={{ y: "-200" }}
					whileInView={{ y: 0 }}
					transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
					viewport={{ once: true }}
					className="HomeHeader-textContainer">
					<h1 className="HomeHeader-primaryText">Tu vehículo eléctrico, <br/> <label>está aquí</label></h1>
					<h6 className="HomeHeader-secondaryText">Financia tu herramienta de trabajo <label>Sin Complicaciones.</label></h6>
					<Link className="default-link"
                     	  to="/#features" 
                     	  key="Organizacion">
						<button className="HomeHeader-button">Saber más</button>
					</Link>
					<img src={require("../assets/images/image3.jpg")} alt="" className="HomeHeader-img3"></img>
				</motion.div>
			</div>
			<motion.img 
				initial={{ x: "-200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
				src={require("../assets/images/image4.jpg")} alt="" className="HomeHeader-img1"></motion.img>
			<motion.img 
				initial={{ x: "-200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
				src={require("../assets/images/image2.png")} alt="" className="HomeHeader-img2"></motion.img>
			<motion.img 
				initial={{ x: "200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
				src={require("../assets/images/image1.jpeg")} alt="" className="HomeHeader-img4"></motion.img>
			<motion.img 
				initial={{ x: "200" }}
				whileInView={{ x: 0 }}
				transition={{ type: "spring", duration: 1.5, bounce: 0.3 }}
				viewport={{ once: true }}
				src={require("../assets/images/image5.jpg")} alt="" className="HomeHeader-img5"></motion.img>
		</div>
		
	);
};

export default HomeHeader;

import React from "react";
import "./Thanks.css";

const Thanks = () => {
	return (
		<div className="thanks-container">
			<h3>¡Muchas gracias por tu interés en Flit!</h3>
			<h3>Muy pronto nuestro equipo se pondrá en contacto contigo</h3>
		</div>
	);
};

export default Thanks;

import React, { useState } from "react";
import Modal from "./Modal";
import { HashLink as Link } from "react-router-hash-link";
import "./Navbar.css";

const props = {
	logo: { src: require("../assets/images/logo/logoFlit.png") },
	options: [
		{ name: "Inicio", link: "/#header" },
		{ name: "Organizacion", link: "/#features" },
		{ name: "Beneficios", link: "/#facts" },
		{ name: "Productos", link: "/#plans" },
	],
};

const Navbar = () => {
	const [color, setColor] = useState(false);
	const changeColor = () => {
		window.scrollY >= 90 ? setColor(true) : setColor(false);
	};
	const [showMenu, setShowMenu] = useState(false);

	const closeMenu = () => {
		setShowMenu(false);
	};

	window.addEventListener("scroll", changeColor);

	return (
		<div className={color ? "navbar-container navbar-bg" : "navbar-container"}>
			<nav>
				<Link className="default-link" 
                to="/#header"
                >
					<img className="logo-image" src={props.logo.src} alt="" />
				</Link>
				<ul>
					{props.options.map(option => (
						<Link className="default-link"
                         to={option.link} 
                         key={option.name}>
							<li>{option.name}</li>
						</Link>
					))}
					<li>
						<Modal buttonText="Contactanos" form={"contact"} />
					</li>
				</ul>
				{!showMenu ? (
					<img
						src={require("../assets/icons/hamburger_icon.png")}
						onClick={() => setShowMenu(true)}
						alt=""
					/>
				) : (
					<img
						src={require("../assets/icons/close_icon_dark.png")}
						alt=""
						onClick={() => setShowMenu(false)}
					/>
				)}
				{showMenu && (
					<div className="mobile-menu">
						<img className="logo-image" src={props.logo.src} alt="" />
						<ul>
							{props.options.map(option => (
								<Link
									className="default-link"
									to={option.link}
									key={option.name}
								>
									<li onClick={closeMenu}>{option.name}</li>
								</Link>
							))}
							<li>
								<Modal buttonText="Contactanos" form={"contact"} />
							</li>
						</ul>
					</div>
				)}
			</nav>
		</div>
	);
};

export default Navbar;
